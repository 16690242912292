import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./LocamartModal.css";
export default function LocamartModal({ onClose, show }) {
  const [error, setError] = useState(false);
  const handleLogin = () => {
    setTimeout(() => {
      setError(true);
      setTimeout(() => {
        onClose();
      }, 3000);
    }, 2000);
  };
  return (
    <Modal
      show={show}
      onHide={onClose}
      size="md"
      centered
      contentClassName="bg-white rounded-3 px-4 pt-10"
    >
      <Modal.Body>
        <h4>Locamart – A Work in Progress 🚀</h4>
        <p className="fs16">
          This is a business I co-founded, and we're actively working on
          building and refining it. While I’m not ready to share the full story
          just yet, stay tuned for updates as we bring Locamart to life!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "#D9EEFF", color: "#30719E" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          onClick={() => window.open("https://locamart.co", "_blank")}
        >
          Visit Temporary Website
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
