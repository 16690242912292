import Main from "../layouts/Main";
import "./Honk.css";
export default function Honk() {
  const style = {
    iconTitle: {
      paddingRight: "15px",
      paddingBottom: "5px",
    },
  };
  return (
    <Main>
      <div id="honk">
        <section className="hero">
          <div className="">
            <img
              src="/assets/img/Honk/Hero.png"
              alt=""
              className="img1 position-absolute d-none d-sm-block"
            />
            <img
              src="/assets/img/Honk/HeroMobile.png"
              alt=""
              className="img1 position-absolute d-block d-sm-none"
            />
          </div>
        </section>
        <section className="prototype">
          <div className="summary">
            <img
              src="/assets/img/Honk/summary.png"
              alt="summary"
              className="d-none d-sm-block"
            />
            <img
              src="/assets/img/Honk/summaryMobile.png"
              alt="summary"
              className="d-block d-sm-none"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            Intro
          </h3>
          <p>
            This case study was part of a UX challenge ​where we wanted to focus
            on solving the UX ​problems in a widely used ​parking mobile app
            Across North America under the guidance of a UX mentor. This
            exercise aimed to understand how ​UX can have an impact on resolving
            main ​business issues through extensive research and ​ideation.
          </p>
          {/* <a href="https://www.onra.ca/" target="_blank" rel="noreferrer">
            <button className="inter">Visit Onra.ca</button>
          </a> */}
          <div className="py-9 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            Context
          </h3>
          <p>
            Honk, is a contactless payment solution designed to simplify parking
            by allowing users to find, book, and pay for parking spaces directly
            from their smartphones. Launched in 2013, Honk is widely used across
            North America and has a presence in thousands of locations in the
            United States and Canada.{" "}
          </p>
          <div className="py-9 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            Design Process
          </h3>
          <img
            src="/assets/img/Honk/design-process.png"
            alt="Problem Statement"
            className="mt-6 img-fluid w-50 d-none d-sm-block"
          />
          <img
            src="/assets/img/Honk/design-process-mobile.png"
            alt="Problem Statement"
            className="mt-6 img-fluid w-50 d-block d-sm-none"
          />
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            Problem Statement
          </h3>
          <img
            src="/assets/img/Honk/problem-statement.png"
            alt="Problem Statement"
            className="mt-6 w-100 d-block d-sm-none"
          />
          <img
            src="/assets/img/Honk/problem-statement.png"
            alt="Problem Statement"
            className="mt-6 w-80 d-none d-sm-block"
          />
          <div className="py-6 py-sm-9"></div>
          <div
            style={{ backgroundColor: "#FCF6E6" }}
            className="d-flex flex-column flex-sm-row justify-content-center w-100 py-16"
          >
            <div className=" col-sm-5 px-9 py-5">
              <h3 className="fs18 fssm32 fw-bold inter text-start">
                Business Objective
              </h3>
              <p className="w-100">
                Increase user satisfaction and engagement by improving the
                overall user experience on the Honk Parking app, making it more
                efficient for users in Canada.{" "}
              </p>
            </div>
            <div className="col-sm-5 px-9 py-5">
              <h3 className="fs18 fssm32 fw-bold inter text-start">
                Expected Outcome
              </h3>
              <p className="w-100">
                A more intuitive and user-friendly Honk Parking app that
                simplifies the parking process, leading to higher user
                satisfaction, increased engagement, and improved user retention.{" "}
              </p>
            </div>
          </div>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            Our Assumptions
          </h3>
          <p>
            Before diving into user research, we spanned out our assumptions
            about what could be the possible issues frustrating users while
            using the Honk app. These assumptions guided our user research
            direction in the next steps.{" "}
          </p>
          <div className="py-3 py-sm-5"></div>
          <img
            src="/assets/img/Honk/assumptions.png"
            alt="Our Assumptions"
            className="img-fluid px-16 my-10 d-none d-sm-block"
          />
          <img
            src="/assets/img/Honk/assumptions-mobile.png"
            alt="Our Assumptions"
            className="img-fluid px-4 my-5 d-block d-sm-none"
          />
          <div className="py-3 py-sm-5"></div>
          <p>
            To understand whether our assumptions are valid , we conducted an
            extensive research to find the potential opportunities toward
            solving the problem. Our goal was to improve the app's user
            engagement rate through applying informed UX improvements.{" "}
          </p>
          <div className="py-6 py-sm-9"></div>
          <div style={{ backgroundColor: "#E6F8FC" }} className="w-100 py-16">
            <h3 className="fs18 fssm32 fw-bold inter text-center">
              <img
                src="/assets/img/Honk/icon-title.png"
                style={style.iconTitle}
                alt="icon"
              />
              Users Reviews Analysis
            </h3>
            <p>
              We started off by analyzing user ‘s reviews on Google play and App
              Store.
            </p>
            <img
              src="/assets/img/Honk/analysis.png"
              alt="Our Assumptions"
              className="img-fluid px-2 px-sm-16 mt-10"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            User Interviews
          </h3>
          <p>
            We interviewed 3 Honk users to understand their pinpoints, needs and
            expectations.{" "}
          </p>
          <img
            src="/assets/img/Honk/interviews.png"
            alt="Our Assumptions"
            className="img-fluid px-3 px-sm-16 my-10"
          />
          <div className="py-6 py-sm-9"></div>
          <div style={{ backgroundColor: "#E6F8FC" }} className="w-100 py-16">
            <h3 className="fs18 fssm32 fw-bold inter text-center">
              <img
                src="/assets/img/Honk/icon-title.png"
                style={style.iconTitle}
                alt="icon"
              />
              Synthesizing Insights
            </h3>
            <p>
              An affinity matrix was utilized to get the patterns and themes out
              of data collected.{" "}
            </p>
            <img
              src="/assets/img/Honk/insights.png"
              alt="Our Assumptions"
              className="img-fluid px-4 px-sm-16 mt-4 mt-sm-10"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            Problem Breakdown
          </h3>
          <p>
            A breakdown of the problem is demonstrated below. This problem
            breakdown is the outcome of gathering insights from analyzing online
            user reviews and interviewing with users.{" "}
          </p>
          <img
            src="/assets/img/Honk/problem.png"
            alt="Problem Breakdown"
            className="img-fluid px-2 px-sm-16 my-10"
          />
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            Personas
          </h3>
          <p>
            Two personas crafted to make sure all the recognized user needs will
            be included in our design process.{" "}
          </p>
          <img
            src="/assets/img/Honk/Persona2.png"
            alt="Personas"
            className="img-fluid px-16 w-70 my-10 d-none d-sm-block"
          />
          <img
            src="/assets/img/Honk/Persona1.png"
            alt="Personas"
            className="img-fluid px-16 w-70 my-10 d-none d-sm-block"
          />
          <img
            src="/assets/img/Honk/Persona2.png"
            alt="Personas"
            className="img-fluid px-4 my-4 d-block d-sm-none"
          />
          <img
            src="/assets/img/Honk/Persona1.png"
            alt="Personas"
            className="img-fluid px-4 mt-4 mb-16 d-block d-sm-none"
          />
          <div style={{ backgroundColor: "#E6F8FC" }} className="w-100 py-16">
            <h3 className="fs18 fssm32 fw-bold inter text-center">
              <img
                src="/assets/img/Honk/icon-title.png"
                style={style.iconTitle}
                alt="icon"
              />
              Competitors Analysis
            </h3>
            <p>
              We examined 3 other similar widely-used parking apps in Canada to
              learn from the strengths and weakness of peer’s solutions to
              problems we found.{" "}
            </p>
            <img
              src="/assets/img/Honk/Competitors.png"
              alt="Our Assumptions"
              className="img-fluid px-sm-16 mt-10"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            How Might We...
          </h3>
          <p>
            We set up an ideation workshop to reflect on the problems found in
            the previous steps by laying out solutions. We used how might we
            technique to promote creativity and efficiency{" "}
          </p>
          <img
            src="/assets/img/Honk/mightwe.png"
            alt="Personas"
            className="img-fluid px-sm-10 my-10"
          />
          <div
            style={{ backgroundColor: "#FCF6E6" }}
            className="w-100 py-16 d-flex flex-column"
          >
            <h3 className="fs18 fssm32 fw-bold inter text-center">
              <img
                src="/assets/img/Honk/icon-title.png"
                style={style.iconTitle}
                alt="icon"
              />
              Impact Effort Matrix
            </h3>
            <p>
              Two personas crafted to make sure all the recognized user needs
              will be included in our design process.
            </p>
            <img
              src="/assets/img/Honk/matrix.png"
              alt="Our Assumptions"
              className="img-fluid px-sm-16 mt-16 mx-auto"
            />
          </div>
          <div
            style={{ backgroundColor: "#E6F8FC" }}
            className="w-100 py-16 d-flex flex-column"
          >
            <h3 className="fs18 fssm32 fw-bold inter text-center">
              <img
                src="/assets/img/Honk/icon-title.png"
                style={style.iconTitle}
                alt="icon"
              />
              New Design:
            </h3>
            <img
              src="/assets/img/Honk/newdesign.png"
              alt="Our Assumptions"
              className="img-fluid px-4 px-sm-16 mt-16 mx-auto"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <img
            src="/assets/img/Honk/newdesign2.png"
            alt="Personas"
            className="img-fluid px-4 px-sm-10 my-6 my-sm-10"
          />
          <div
            style={{ backgroundColor: "#FCF6E6" }}
            className="w-100 py-sm-16 d-flex flex-column"
          >
            <img
              src="/assets/img/Honk/newdesign3.png"
              alt="Our Assumptions"
              className="img-fluid px-4 px-sm-16 mt-8 mt-sm-16 mx-auto"
            />
          </div>
          <div className="py-6 py-sm-9"></div>
          <h3 className="fs18 fssm32 fw-bold inter text-center">
            <img
              src="/assets/img/Honk/icon-title.png"
              style={style.iconTitle}
              alt="icon"
            />
            Key Takeaways
          </h3>
          <p>Given the user research and usability testing we learned that: </p>
          <p>
            People are tend to have a versatile parking application where all
            their parking needs are address all together at one place. Although
            there are many parking apps out there, they all lack addressing some
            specific users needs. For example, while some of parking apps
            address post-parking experience such as (pay by Zone ID), some app
            don't support paying by zone-Id and they only address pre-booking
            needs such as finding parking spot, get directions and booking
            parking spots in advance. As a result, people may need to install
            various parking mobile apps to fulfill various needs.{" "}
          </p>
          <p>
            In parking applications, time is the first and foremost concern
            among users as they usually are in a rush and might be driving while
            using these apps.{" "}
          </p>
          <p>
            Depending on the situation, people may have different preferences
            regarding receiving parking session extension notifications.
            Customizing notification setting is a must-have feature in parking
            applications.
          </p>
          <p>
            Given the fact that a parking application may not be able to list
            all the parkings available across the city, users can easily get
            upset if they search for a specific location yet the application is
            not smart enough to react the location prompted by user. Location
            communication must be clear and consistent among the app. In case a
            requested location is not available, user needs to be informed
            clearly and the next close available spot should be offered by the
            app.
          </p>
          <p>
            People who use parking applications are usually price-sensitive.
            They would like to trust the parking app would nt up-charge them via
            the app.
          </p>
          <div className="py-6 py-sm-9"></div>
          <div className="py-6 py-sm-9"></div>
        </section>
      </div>
    </Main>
  );
}
