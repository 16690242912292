import OffcanvasX from "react-bootstrap/Offcanvas";
import { BsBehance, BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineFileDownload } from "react-icons/md";
import { CiYoutube } from "react-icons/ci";

export default function Offcanvas(props) {
  const style = {
    social: {
      display: "inline-block",
      borderRadius: "50%",
      backgroundColor: "var(--bs-main)",
      color: "white",
      width: "34px",
      height: "34px",
      paddingTop: "0px",
      paddingLeft: "7px",
    },
    socialIcon: {
      // width: "34px",
      // height: "34px",
    },
    logo: {
      width: "45px",
    },
  };
  return (
    <OffcanvasX
      show={props?.show}
      onHide={props?.handleClose}
      placement={props?.placement}
      id="menu-offcanvas"
    >
      <OffcanvasX.Header>
        <img
          src="/assets/img/Home/ME_Logo.png"
          alt="LOGO"
          className="logo"
          style={style.logo}
        />
        <IoCloseOutline
          style={{ width: "35px", height: "35px", color: "var(--bs-main)" }}
          onClick={props?.handleClose}
          role="button"
        />
      </OffcanvasX.Header>
      <OffcanvasX.Body>
        <div className="navbar-list">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/onra" className="ms-4 d-block d-sm-none">
            &#8226; Onra
          </NavLink>
          <NavLink to="/honk" className="ms-4 d-block d-sm-none">
            &#8226; Honk
          </NavLink>
          <NavLink to="/sib_bakery" className="ms-4 d-block d-sm-none">
            &#8226; Sib Bakery
          </NavLink>
          <NavLink to="/ecycle" className="ms-4 d-block d-sm-none">
            &#8226; eCycle
          </NavLink>
          <NavLink to="/locamart" className="ms-4 d-block d-sm-none">
            &#8226; Locamart
          </NavLink>
          <NavLink to="/other" className="ms-4 d-block d-sm-none">
            &#8226; What Else I do
          </NavLink>
          <a
            href="/assets/Mahsa_Ebrahim_CV.docx"
            target="_blank"
            rel="noreferrer"
            className=""
            download
          >
            Resume <MdOutlineFileDownload />
          </a>{" "}
          {/* <NavLink to="/about">About</NavLink>
          <NavLink to="/contact">Contact</NavLink> */}
          {/* <NavLink className="d-none d-sm-block" to="/blog">
            Blog
          </NavLink> */}
          <div style={{ bottom: "10px", position: "absolute" }}>
            <a
              className="me-2"
              href="https://www.linkedin.com/in/mahsa-hm-ebrahim/"
              target="_blank"
              rel="noreferrer"
              style={style.social}
            >
              <FaLinkedinIn style={style.socialIcon} />
            </a>
            <a
              className="me-2"
              href="https://www.youtube.com/@design_with_mahsa"
              target="_blank"
              rel="noreferrer"
              style={style.social}
            >
              <CiYoutube style={style.socialIcon} />
            </a>
            <a
              className="me-2"
              href="https://www.behance.net/mahsaeb"
              target="_blank"
              rel="noreferrer"
              style={style.social}
            >
              <BsBehance style={style.socialIcon} />
            </a>
            <a
              className="me-2"
              href="https://www.instagram.com/moonsa_art/?hl=en"
              target="_blank"
              rel="noreferrer"
              style={style.social}
            >
              <BsInstagram style={style.socialIcon} />
            </a>
          </div>
        </div>
      </OffcanvasX.Body>
    </OffcanvasX>
  );
}
