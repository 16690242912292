import { Link } from "react-router-dom";
import "./Footer.css";
export default function Footer() {
  return (
    <div id="footer" className="">
      <span className="fs16 text-white inter mb-8">
        Designed by Mahsa Ebrahim - {new Date().getFullYear()}
      </span>
      {/* <img src="/assets/img/Home/footer-bg.svg" alt="" /> */}
    </div>
  );
}
