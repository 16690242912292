import { useNavigate } from "react-router";
import { useState } from "react";
import Main from "../layouts/Main";
import { motion } from "framer-motion";
import { BsInstagram, BsBehance } from "react-icons/bs";
import { CiYoutube } from "react-icons/ci";

import { GoLightBulb } from "react-icons/go";
import { FaLinkedinIn, FaArrowRight } from "react-icons/fa";
import { MdOutlineManageSearch } from "react-icons/md";
import { RiUserVoiceLine } from "react-icons/ri";
import { VscSymbolColor } from "react-icons/vsc";
import { PiYoutubeLogoLight } from "react-icons/pi";
import { VscSparkle } from "react-icons/vsc";

import useModal from "../hooks/useModal/useModal";
import LoginModal from "./LoginModal";
import LocamartModal from "./LocamartModal";
import "./HomePage.css";
export default function HomePage() {
  const navigate = useNavigate();
  const [showLoginModal, handleCloseLoginModal, handleShowLoginModal] =
    useModal();
  const [showLocamartModal, handleCloseLocamartModal, handleShowLocamartModal] =
    useModal();
  const [isWork, setIsWork] = useState(true);
  const handleIsWork = (status) => {
    setIsWork(status);
  };

  return (
    <Main>
      <div id="homepage">
        <section className="d-flex flex-column flex-lg-row hero mx-auto">
          <div className="introduction order-2 order-lg-1">
            {isWork ? (
              <>
                <div className="hi-text fw-bold">
                  Hi! I'm <span className="text-main">Mahsa</span>
                </div>
                <div className="sub-text-role">
                  My journey from art, animation, and graphic design to product
                  design has shaped how I solve problems and craft user
                  experiences. These three principles guide my work:
                </div>
                <div className="card">
                  <div className="title">
                    <GoLightBulb />
                    Strategic design thinking
                  </div>
                  <div className="description">
                    I start every project by asking Why? and How can this create
                    real value for users?
                  </div>
                </div>
                <div className="card">
                  <div className="title">
                    <MdOutlineManageSearch />
                    Precision in details
                  </div>
                  <div className="description">
                    From micro interactions to design systems, I believe the
                    smallest details create the biggest impact.
                  </div>
                </div>
                <div className="card">
                  <div className="title">
                    <RiUserVoiceLine />
                    Communication & storytelling
                  </div>
                  <div className="description">
                    Great design isn’t just visuals; it’s about presenting ideas
                    effectively and engaging users.
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="hi-text fw-bold">
                  <span className="text-main">Beyond</span> Work...
                </div>
                <div className="sub-text-role">
                  I explore creativity through art, crafting, content creation,
                  and music while sharing knowledge and building connections.{" "}
                </div>
                <div className="card">
                  <div className="title">
                    <VscSymbolColor />
                    Artist at heart
                  </div>
                  <div className="description">
                    I’m passionate about creating—whether through painting,
                    crafting, writing, or video editing—and exploring new ways
                    to tell stories.{" "}
                  </div>
                </div>
                <div className="card">
                  <div className="title">
                    <PiYoutubeLogoLight />
                    Content creator & educator
                  </div>
                  <div className="description">
                    Sharing knowledge excites me! I’ve built a UX/UI community
                    on YouTube, where I teach, learn, and connect with fellow
                    designers.{" "}
                  </div>
                </div>
                <div className="card">
                  <div className="title">
                    <VscSparkle />
                    Creative soul
                  </div>
                  <div className="description">
                    Music, dance, puzzles, and organizing gatherings fuel my
                    creativity. Currently learning piano.
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="profile-section order-1 order-lg-2">
            <div className="btn-group" role="group" ariaLabel="">
              <button
                type="button"
                className={`btn ${isWork ? "active" : ""}`}
                onClick={() => handleIsWork(true)}
              >
                Work
              </button>
              <button
                type="button"
                className={`btn ${isWork ? "" : "active"}`}
                onClick={() => handleIsWork(false)}
              >
                Life
              </button>
            </div>
            {isWork ? (
              <img
                src="/assets/img/Home/me-profile-pic.png"
                alt="portfolio face"
                className=""
              />
            ) : (
              <img
                src="/assets/img/Home/me-profile-pic-life.png"
                alt="portfolio face"
                className=""
              />
            )}
          </div>
        </section>
        <div className="py-4 py-lg-16 my-4"></div>
        <section id="section-youtube">
          <div className="title dm-sans">
            Join Me on My Tutorial Journey on YouTube!
          </div>
          <div className="card mt-5">
            <a
              href="https://www.youtube.com/@design_with_mahsa"
              target="_blank"
            >
              <img
                src="/assets/img/Home/youtube-thumbnail.png"
                alt=""
                className="img-fluid d-none d-sm-block"
              />
              <img
                src="/assets/img/Home/youtube-thumbnail-mobile.png"
                alt=""
                className="img-fluid d-block d-sm-none mx-auto"
              />
            </a>
          </div>
        </section>
        {/* <section className="row align-items-center justify-content-center">
          <div
            className="row justify-content-center justify-content-sm-end px-0"
            style={{
              width: "75%",
              maxWidth: "1080px",
            }}
          >
            <a
              href="https://cdn.knightlab.com/libs/juxtapose/latest/embed/index.html?uid=08e3ae56-36d4-11ee-b5bd-6595d9b17862"
              target="_blank"
              rel="noreferrer"
              className="col-12 col-sm-6 px-0"
            >
              <div
                className="crafting-video-container d-flex justify-content-center me-sm-4"
                style={{
                  backgroundColor: "#5193C6",
                  borderRadius: "25px",
                }}
              >
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  preload="auto"
                  alt=""
                  style={{ padding: "3%", borderRadius: "25px" }}
                  className="img-fluid crafting-video"
                >
                  <source
                    src={"/assets/video/juxtapose.mp4"}
                    type="video/mp4"
                  />
                </video>
              </div>
            </a>
            <div className="col-12 col-sm-6 d-flex flex-column justify-content-center px-0 mt-4 mt-sm-0 ps-sm-10">
              <div
                className="crafting-title fw-bold mb-sm-4"
                style={{ color: "#5193C6", lineHeight: 1.2 }}
              >
                Crafting Seamless Interfaces
              </div>
              <div className="crafting-subtitle">
                Transforming Ideas into Seamless Interfaces. Specializing in
                Product Design, Branding, and Web Solutions to Elevate User
                Experiences
              </div>
            </div>
          </div>
        </section> */}
        <div className="py-12 py-sm-8"></div>
        <div className="w-100" id="sec-cards-wrapper">
          <div className="works-title fw-bold text-center text-main py-5">
            Selected Works
          </div>
          <section id="sec-cards">
            <div
              className="card"
              onClick={() => navigate("/onra")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/onra.png"
                  className="card-img-top"
                  alt="Onra.ca"
                />
              </div>
              <div className="card-body">
                <h5 className="card-title">
                  Creating a Seamless and Informative Real Estate Platform for
                  Home Buyers
                </h5>
                <div className="d-flex justify-content-between align-items-center mb-4 mb-sm-0">
                  <p className="card-text">https://www.onra.ca 2023</p>
                  <a className="btn" onClick={() => navigate("/onra")}>
                    <FaArrowRight style={{ width: "18px" }} />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/honk")}
              role="button"
            >
              <div
                className="img-container"
                style={{
                  paddingTop: "0",
                  paddingBottom: "0",
                  alignItems: "flex-start",
                }}
              >
                <img
                  src="/assets/img/Home/honk.png"
                  className="card-img-top"
                  alt="Onra.ca"
                />
              </div>
              <div className="card-body">
                <h5 className="card-title">
                  Enhancing User Engagement on a Parking App
                </h5>
                <div className="d-flex justify-content-between align-items-center mb-4 mb-sm-0">
                  <p className="card-text">Honk - 2024</p>
                  <a className="btn" onClick={() => navigate("/honk")}>
                    <FaArrowRight style={{ width: "18px" }} />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/sib_bakery")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/Sib-Bakery.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <h5 className="card-title">
                  Designing a Trustworthy and Delightful Website for a Homemade
                  Bakery
                </h5>
                <div className="d-flex justify-content-between align-items-center mb-4 mb-sm-0">
                  <p className="card-subtext">Sib Bakery - 2022 </p>
                  <a className="btn" onClick={() => navigate("/sib_bakery")}>
                    <FaArrowRight style={{ width: "18px" }} />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/ecycle")}
              role="button"
            >
              <div
                className="img-container"
                style={{
                  paddingTop: "0",
                  paddingBottom: "0",
                }}
              >
                <img
                  src="/assets/img/Home/eCycle.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">
                    Enhancing the E-Bike Rental Experience with a User-Friendly
                    App{" "}
                  </h5>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4 mb-sm-0">
                  <p className="card-subtext">eCycle - 2021 </p>
                  <a className="btn" onClick={() => navigate("/ecycle")}>
                    <FaArrowRight style={{ width: "18px" }} />
                  </a>
                </div>
              </div>
            </div>
            {/* <div
              className="card"
              onClick={() => navigate("/plando")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/Left_laptop.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">Plando</h5>
                  <p className="card-text">
                    Planner and Goal-tracking Web Application
                  </p>
                  <p className="card-subtext">
                    Offering a customizable planner to monitor progress
                  </p>
                </div>
                <a className="btn" onClick={() => navigate("/plando")}>
                  <FaArrowRight style={{ width: "18px" }} />
                </a>
              </div>
            </div>

            <div
              className="card"
              onClick={() => navigate("/ftd")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/Laptop_FTD_Home.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">FTD </h5>

                  <p className="card-text">Flower Online Shop Website </p>
                  <p className="card-subtext">
                    Redesign the homepage with a focus on adhering to WCAG
                    standards criteria
                  </p>
                </div>
                <a className="btn" onClick={() => navigate("/ftd")}>
                  <FaArrowRight style={{ width: "18px" }} />
                </a>
              </div>
            </div>
            */}
            {/* <div
              className="card"
              onClick={() => navigate("/apple-dry")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/apple-dry.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">Apple Dry</h5>
                  <p className="card-text">
                    Online Shop for Organic Dried Fruits
                  </p>
                  <p className="card-subtext">
                    Providing easy access to buy healthy dried fruit
                  </p>
                </div>
                <a className="btn" onClick={() => navigate("/apple-dry")}>
                  <FaArrowRight style={{ width: "18px" }} />
                </a>
              </div>
            </div> */}
            <div
              className="card"
              onClick={handleShowLocamartModal}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/locamart.png"
                  className="card-img-top"
                  alt="locamart"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">
                    Boosting Sales and Connecting Local Businesses with
                    Customers{" "}
                  </h5>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4 mb-sm-0">
                  <p className="card-text">https://www.locamart.co/ </p>
                  <a className="btn" onClick={handleShowLocamartModal}>
                    <FaArrowRight style={{ width: "18px" }} />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/other")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/Other_Home.png"
                  className="card-img-top"
                  alt="other"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">What Else I Do </h5>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4 mb-sm-0">
                  <p className="card-text">
                    Graphic Design, Illustration, Motion Design{" "}
                  </p>
                  <a className="btn" onClick={() => navigate("/other")}>
                    <FaArrowRight style={{ width: "18px" }} />
                  </a>
                </div>
              </div>
            </div>
            {/* <div
              className="card"
              onClick={() => handleShowLoginModal()}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/hillzd.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">Hillz Dealer </h5>
                  <p className="card-text">
                    Web-based Dealer Management System
                  </p>
                  <p className="card-subtext">
                    Designed specifically for the used car dealership
                  </p>
                </div>

                <a className="btn" onClick={() => handleShowLoginModal()}>
                  View{" "}
                  <FaArrowRight style={{ width: "18px" }}  />
                </a>
              </div>
            </div> */}
          </section>
          <div className="py-4"></div>
        </div>
        <section id="sec-skills">
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-lg-content-between">
            <div className="col-sm d-flex flex-column justify-content-center align-items-center order-2 order-sm-1">
              <div className="paper-plane">
                <motion.svg
                  width="335"
                  height="115"
                  viewBox="0 0 462 158"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <motion.polygon
                    fill="#30719E"
                    className="morph"
                    // points="460.677372 157.296036 112.758683 38.9677241 119.111306 145.719727"
                    initial={{
                      points:
                        "460.677372 157.296036 112.758683 38.9677241 119.111306 145.719727",
                    }}
                    animate={{
                      points: [
                        "460.677372 125.296036 108.040017 24.8652344 112.454079 125.296036",
                        "460.677372 157.296036 112.758683 38.9677241 119.111306 145.719727",
                      ],
                    }}
                    transition={{
                      repeat: Infinity,
                      duration: 2,
                      repeatType: "reverse",
                    }}
                  />
                  <motion.polygon
                    fill="#5193C6"
                    className="morph2"
                    // points="112.758683 38.9677241 184.146186 0 460.677372 157.296036"
                    initial={{
                      points:
                        "112.758683 38.9677241 184.146186 0 460.677372 157.296036",
                    }}
                    animate={{
                      points: [
                        "108.040017 24.8652344 168.780251 0 460.677372 125.296036",
                        "112.758683 38.9677241 184.146186 0 460.677372 157.296036",
                      ],
                    }}
                    transition={{
                      repeat: Infinity,
                      duration: 2,
                      repeatType: "reverse",
                    }}
                  />
                  <motion.polygon
                    fill="#5193C6"
                    className="morph3"
                    // points="78.250435 57.8044647 460.677372 157.296036 0 100.518402"
                    initial={{
                      points:
                        "78.250435 57.8044647 460.677372 157.296036 0 100.518402",
                    }}
                    animate={{
                      points: [
                        "75.3147561 38.1354167 460.677372 125.296036 0 68.5184024",
                        "78.250435 57.8044647 460.677372 157.296036 0 100.518402",
                      ],
                    }}
                    transition={{
                      repeat: Infinity,
                      duration: 2,
                      repeatType: "reverse",
                    }}
                  />
                </motion.svg>
              </div>
              <div className="text-main text-center mt-10 fs24 fw-bold">
                Sometimes, a paper ball can <br /> turn into a paper plane!
              </div>
            </div>
            <div className="row col-12 col-sm list text-main order-1 order-sm-2">
              <div className="fw-bold title">Skills</div>
              <div className="col-sm-6">
                <span className="fw-bold">UX</span>
                <ul>
                  <li>Agile Methodology</li>
                  <li>UX Strategy | Design Thinking</li>
                  <li>Design System</li>
                  <li>Information Architecture</li>
                  <li>Competitive Analysis</li>
                  <li>Usability Testing | A/B Testing</li>
                  <li>Human Interface Guidelines</li>
                  <li>Wireframing | Prototyping</li>
                  <li>Interaction Design</li>
                  <li>Responsive Design</li>
                </ul>
              </div>
              <div className="col-sm-6">
                <span className="fw-bold">Problem Solving</span>
                <ul className="problem-solving">
                  <li>Brand Identity</li>
                  <li>Marketing Strategy</li>
                  <li>User Psychology</li>
                  <li>Project Management</li>
                  <li>Storytelling</li>
                </ul>
                <span className="fw-bold">UI+ Visuals</span>
                <ul>
                  <li>Graphic Design</li>
                  <li>Desktop & phone Design</li>
                  <li>Sketch | Figma | Xd</li>
                  <li>Adobe Creative Suite</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="py-8"></div>
          <div className="social-container2">
            <a
              className="social-icon"
              href="https://www.linkedin.com/in/mahsa-hm-ebrahim/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a
              className="social-icon"
              href="https://www.youtube.com/@design_with_mahsa"
              target="_blank"
              rel="noreferrer"
            >
              <CiYoutube />
            </a>
            <a
              className="social-icon"
              href="https://www.behance.net/mahsaeb"
              target="_blank"
              rel="noreferrer"
            >
              <BsBehance />
            </a>
            <a
              className="social-icon"
              href="https://www.instagram.com/moonsa_art/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram />
            </a>
          </div>
        </section>
        <LoginModal show={showLoginModal} onClose={handleCloseLoginModal} />
        <LocamartModal
          show={showLocamartModal}
          onClose={handleCloseLocamartModal}
        />
      </div>
    </Main>
  );
}
